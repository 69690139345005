import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Head Office",
            description: (
              <>
                <Address>
                  <AddressLine>288 Musgrave Road, Coopers Plains,</AddressLine>
                  <AddressLine>QLD, Australia 4108</AddressLine>
                </Address>
                <Email>solutions@metromidas.com</Email>
                <Phone>+61 40 378 60 83</Phone>
              </>
            )
          },
          {
            title: "Development Center",
            description: (
              <>
                <Address>
                  <AddressLine>904-905, Pehel Lake View, Near, Vaishnodevi Circle</AddressLine>
                  <AddressLine> Ahmedabad, Gujarat 382421</AddressLine>
                </Address>
                <Email>solutions@metromidas.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
        
      
  
        
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

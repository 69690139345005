import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import OilGas from "../../images/banking.jpg"
import Banking2 from  "../../images/banking-and-finance.svg"
import bfsi from  "../../images/bfsi3-removebg-preview.png"
import ai1 from "../../images/ai1.png"
import ai2 from "../../images/ai2.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="Artificial Intelligence & Machine Learning Services"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="We specialize in Artificial Intelligence (AI) and Machine Learning (ML) solutions that empower businesses to make data-driven decisions, automate processes, and drive innovation. Our expert team of AI/ML engineers works closely with you to build intelligent systems that deliver real-world value and improve operational efficiency."
       imageSrc={ai2}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Ready to Unlock the Power of AI & ML?"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="At Metromidas, we are committed to helping businesses integrate AI and Machine Learning into their operations to drive innovation, increase efficiency, and gain a competitive edge. Whether you are just starting with AI or looking to scale your ML initiatives, we have the expertise to guide you every step of the way"
        
        imageSrc={ai1}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";

import saas2 from "../../images/saas2.png"
import  saas3 from "../../images/saas3.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="SaaS Development Services"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="We specialize in delivering Software as a Service (SaaS) solutions that empower businesses to scale, innovate, and stay ahead of the competition. With the shift to the cloud, businesses can access powerful software applications on-demand, reducing infrastructure costs, improving accessibility, and boosting collaboration.

Whether you need to build a new SaaS application or optimize your existing one, our expert team delivers end-to-end SaaS development services designed to meet your unique business needs."
       imageSrc={saas3}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Build, Scale, and Innovate with SaaS"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="At Metromidas, we are dedicated to helping businesses unlock the full potential of Software as a Service (SaaS). Whether you're looking to build a custom SaaS application, migrate to the cloud, or optimize your existing platform, we provide the expertise and support needed to succeed in today’s competitive marketplace."
        
        imageSrc={saas2}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

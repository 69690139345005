import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import OilGas from "../../images/banking.jpg"
import Banking2 from  "../../images/banking-and-finance.svg"
import bfsi from  "../../images/bfsi3-removebg-preview.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="BFSI Software Development Solutions"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11/bfsi"
       description="The BFSI (Banking, Financial Services, and Insurance) industry is undergoing a tech-driven transformation, with increasing customer demands for secure, user-friendly solutions amidst rising security concerns and tighter government regulations. Financial institutions need an IT partner who understands their unique challenges and can deliver tailored software solutions.

Metromidas is a trusted partner for businesses of all sizes in the BFSI sector, offering expertise in core banking, payments, wealth management, insurance systems, data analytics, stock trading, payment gateways, and more. We work closely with clients to create custom solutions that address their specific needs and drive business success."
       imageSrc={bfsi}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Banking, Financial Services and Insurance"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description=" In the emerging technological environment, we are offering reliable banking and financial software development services and innovative insurance solutions to help you stay ahead and accelerate in this sector comply with all industry norms and standards."
        
        imageSrc={Banking2}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import abtus1 from "../images/abtus1.png"
import abtus2 from "../images/abtus2.png"

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <MainFeature1
        subheading={<Subheading>About MetroMidas</Subheading>}
        heading="We are a catalyst for your digital transformation."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        primaryButtonUrl = "/"
        imageSrc={abtus1}/>
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We envision a future where technology seamlessly integrates into everyday life."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="We envision a future where technology seamlessly integrates into everyday life, making it simpler, more efficient, and more enjoyable. By fostering innovation and collaboration, we aim to be at the forefront of technological advancements that shape industries and empower individuals."
        imageSrc={abtus2}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description=" we are guided by a commitment to innovation, integrity, and collaboration, ensuring that we deliver exceptional solutions tailored to our clients' needs."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "You can count on us for consistent and dependable service."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "We always tailor our services to fit your unique needs."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
           description: "Enjoy quick response times and efficient service delivery."
          }
        ]}
        linkText=""
      />
      <DashedBorderSixFeatures />
    
      <Footer />
    </AnimationRevealPage>
    
  );
};

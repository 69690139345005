import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";

import qa1 from "../../images/qa1.png"
import qa2 from "../../images/qa2.png"


import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="Quality Assurance Services"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="At Metromidas, we understand that quality is at the core of every successful software solution. Our Quality Assurance (QA) Services ensure that your products meet the highest standards of functionality, performance, and user satisfaction. By combining manual and automated testing methodologies, our expert QA team works diligently to detect issues early, improve product quality, and deliver flawless solutions."
       imageSrc={qa2}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Let Us Ensure the Quality of Your Product"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="Quality assurance is not just about testing; it’s about ensuring that every piece of your software is in perfect working order before reaching your users. At Metromidas, our QA specialists are committed to making sure your software performs flawlessly, every time.

Get in touch today to learn more about how our QA services can help you deliver exceptional products and enhance customer satisfaction."
        
        imageSrc={qa1}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

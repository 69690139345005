import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";

import sdev1 from "../../images/sdev1.png"
import sdev2 from "../../images/sdev2.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="Software Development Company"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="
Metromidas is a premier software development company where passion drives innovation, and expertise meets excellence. Our team of in-house subject matter experts across various industries provides invaluable insights into both current and emerging software needs.

We are recognized for crafting cutting-edge digital solutions, utilizing next-generation technologies to deliver impactful results. Connect with our software development experts today and stay ahead of the competition."
       imageSrc={sdev2}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Robust Software Development Services for All Industries"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="At Metromidas, we deliver robust software development services tailored to meet the unique needs of businesses across all industries. From custom solutions to system integrations, our expert team leverages the latest technologies to create secure, scalable, and high-performance software."
        
        imageSrc={sdev1}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

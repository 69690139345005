import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-1000`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
      {
        imageSrc: ShieldIconImage,
        title: "Software Engineering",
        description: "We provide end-to-end software engineering solutions, from system architecture to custom software development. Our team designs robust, scalable applications tailored to your business needs, ensuring high performance and security."
      },
      {
        imageSrc: FastIconImage,
        title: "Application Development",
        description: "Our application development services focus on creating intuitive and feature-rich applications for web, mobile, and desktop platforms. We deliver seamless user experiences with scalable solutions, keeping your users' needs at the core of our design process."
      },
      {
        imageSrc: ReliableIconImage,
        title: "Data Engineering",
        description: "Harness the power of your data with our data engineering services. We help you build efficient data pipelines, integrate complex systems, and ensure smooth data flow, enabling you to make data-driven decisions with accuracy and speed."
      },
      {
        imageSrc: SimpleIconImage,
        title: "AI/ML",
        description: "Our AI and Machine Learning services help you leverage advanced algorithms and data models to unlock actionable insights, automate processes, and improve decision-making. We implement custom AI/ML solutions that solve your unique business challenges."
      },
      {
        imageSrc: SimpleIconImage,
        title: "Automation",
        description: "Increase efficiency and reduce manual errors with our automation services. We design and implement end-to-end automation solutions that streamline workflows, enhance productivity, and ensure consistent results across your operations."
      },
      {
        imageSrc: CustomizeIconImage,
        title: "Quality Assurance",
        description: "Our quality assurance services ensure that your software products meet the highest standards of performance, security, and usability. Through rigorous testing, we identify potential issues and provide actionable insights to improve the quality of your product."
      },
      {
        imageSrc: SimpleIconImage,
        title: "IT Services",
        description: "We offer comprehensive IT services designed to optimize your technology infrastructure. From network management to cloud solutions, our team ensures that your systems are secure, reliable, and aligned with your business goals."
      },
      {
        imageSrc: SimpleIconImage,
        title: "SaaS",
        description: "Our SaaS (Software as a Service) solutions provide scalable, cloud-based applications that deliver value through flexibility and ease of access. We help you develop and deploy SaaS products that meet your customers' evolving needs while optimizing performance and cost."
      }
    
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-1000">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

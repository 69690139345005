import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import ThreeColCenteredStatsPrimaryBackground from "components/features/ThreeColCenteredStatsPrimaryBackground";
import heroScreenshotImageSrc from "images/Growth.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-1000`;
  const HighlightedText = tw.span`text-primary-1000`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Elevate Your Software Development Experience with <HighlightedText> Metro Midas </HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl = "/aboutus"
      />
      <ThreeColCenteredStatsPrimaryBackground />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        primaryButtonUrl = "/aboutus"
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
   
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://web.archive.org/web/20240523203424im_/https://metromidas.com/wp-content/uploads/Image_Editor-18-modified-1-150x150.png",
            heading: "Amazing User Experience",
            quote:
              "The team at MetroMidas is incredibly knowledgeable and responsive. They took the time to understand our needs and delivered solutions that exceeded our expectations. I highly recommend them!",
            customerName: "Zevar",
            customerTitle: ""
          },
          {
            stars: 5,
            profileImageSrc:
              "https://web.archive.org/web/20240523200826im_/https://metromidas.com/wp-content/uploads/Image_Editor-17-modified-1-150x150.png",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Working with MetroMidas transformed our business operations. Their team implemented a custom ERP solution that streamlined our processes and increased our efficiency by 30%. We couldn't be happier with the results!",
            customerName: "Umiya Transport ",
            customerTitle: ""
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What services does you offer?",
            answer:
              "we offer a range of services to ensure comprehensive compliance management across various industries. Our state-of-the-art Compliance Management Platform handles the rigorous demands of compliance with real-time monitoring, automated reporting, and seamless integration with your systems. We provide industry-specific solutions like Logistics, Agriculture, Renewable Energy, Law and many more."
          },
          {
            question: "What technologies do you specialize in?",
            answer:
              `We specialize in a range of technologies, including:
Programming languages (Java, Python, JavaScript, etc.)
Frameworks (React, Angular, .NET, etc.)
Cloud platforms (AWS, Azure, Google Cloud)
Databases (MySQL, MongoDB, PostgreSQL)
`
          },
          {
            question: "How do you ensure client satisfaction?",
            answer:
              `We prioritize client feedback throughout the project lifecycle, conduct regular check-ins, and make adjustments based on client needs and preferences.
`
          }
        ]}
      />
      <div id="contact-us">
  <TwoColContactUsWithIllustrationFullForm />
</div>
      <GetStarted/>

    </AnimationRevealPage>
  );
}

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/metro-midas-logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "images/insta1.svg";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mb-8 px-8 overflow-hidden`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8 overflow-hidden`
const FiveColumns = tw.div`flex flex-wrap justify-between overflow-hidden`;

const ColumnsContainer = tw.div`flex justify-between`;



const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left flex-1`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
        <ColumnsContainer>
          <Column >
            <ColumnHeading>Dedicated R</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/technologies/frontend">FrontEnd</Link>
              </LinkListItem>
              <LinkListItem>
                Angular
              </LinkListItem>
              <LinkListItem>
                ReactJs
              </LinkListItem>
              <LinkListItem>
                VueJS
              </LinkListItem>
              <LinkListItem>
                UI/UX
              </LinkListItem>
            </LinkList>
            
            
          </Column>
          </ColumnsContainer>
          
          <Column>
            <ColumnHeading> esources</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/technologies/backend">BackEnd</Link>
              </LinkListItem>
              <LinkListItem>
              Ruby on Rails
              </LinkListItem>
              <LinkListItem>
              NodeJS
              </LinkListItem>
              <LinkListItem>
              Golang
              </LinkListItem>
              <LinkListItem>
              Java
              </LinkListItem>
              <LinkListItem>
              Python
              </LinkListItem>
              <LinkListItem>
              PHP
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Our Focus</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/services/quality-assurance">Software Testing and QA</Link>
              </LinkListItem>
  
            </LinkList>
            <br></br>
            <ColumnHeading>Development</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/services/software-development">Software Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/services/application-development">Mobile Application Development</Link>
              </LinkListItem>
              <LinkListItem>
              Cross Platform App Development
              </LinkListItem>
              <LinkListItem>
              Chatbot Development
              </LinkListItem>
              <LinkListItem>
              Software Product Engineering
              </LinkListItem>
            </LinkList>
          </Column>
          
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                +61 40 378 60 83
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:solutions@metromidas.com">solutions@metromidas.com</Link>
              </LinkListItem>
             
            </LinkList>
          </Column>

          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              
            </LogoContainer>
            <CompanyAddress>
            288 Musgrave Road, Coopers Plains, QLD, Australia 4108
            </CompanyAddress>
            <CompanyAddress>
            904-905, Pehel Lake View, Near, Vaishnodevi Circle, beside Auda Lake, Khoraj, Ahmedabad, Gujarat 382421
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/metromidas/">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com/metromidas?lang=en">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/metromidas/">
                <LinkedinIcon />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/metromidas/">
                <InstagramIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
        </FiveColumns>

      </Content>
    </Container>
  );
};

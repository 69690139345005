import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import Logistics from "../../images/logistics-fundamentals-supply-chain.webp"
import Logistics2 from "../../images/logistics2.jpeg"
import Logistics3 from "../../images/logistics3.webp"
import logi2 from "../../images/logi2.png"
import logi3 from "../../images/logi3.png"


import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="Custom Mobility Solutions for Transportation and Logistics Industry"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="Manage your logistics and transportation business at the ease of your fingertips. Schedule a 30-minute discussion about your Logistics Application Development Project."
       imageSrc={logi2}
       />

<MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Tailored Mobility Solutions to Optimize Operations and Drive Innovation in the Transportation and Logistics Industry."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="Build a real time mobile app for your business to establish a seamless synchronization across your logistics and transportation business. Our robust and highly scalable mobile app solution can improve operational performance, automate your shipping, gain real-time visibility into supply chains and help you to achieve superior on-time delivery performance.
"
        imageSrc={logi3}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";

import NodejsIcon from "../../images/icons8-nodejs.svg"
import PythonIcon from "../../images/icons8-python.svg"
import JavaIcon from "../../images/icons8-java.svg"
import RubyIcon from "../../images/icons8-ruby-programming-language.svg"
import PhpIcon from "../../images/icons8-php.svg"
import BackendImage1 from "../../images/backendicon.png"
import BackendImage2 from "../../images/backend2.svg"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      {/* Hero Section */}
      <TwoColumnWithVideo
        heading="Powerful Backend Technologies for Scalable Applications"
        buttonRounded={false}
        primaryButtonText="Request Free Consultation"
        primaryButtonUrl="https://calendly.com/solutions-tbnw/30min?month=2024-11"
        description="We specialize in backend development using technologies like Node.js, Python, Java, and Ruby to build scalable, secure, and high-performance applications. Our team builds APIs, microservices, databases, and systems that power the most demanding business applications."
        imageSrc={BackendImage1}
      />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Our Expertise</Subheading>}
        heading="Need a Robust Backend Solution?"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contactus"
        description="Our backend developers are experts in server-side technologies that ensure your app is fast, reliable, and secure. Whether you need to build an API, a custom database solution, or microservices, we’ve got you covered."
        imageSrc={BackendImage2}
        textOnLeft={false}
      />

      {/* Feature Section */}
      <Features
        heading="Backend Technology Stack"
        description="We use modern, flexible backend technologies to provide highly scalable and efficient backend solutions. From server-side logic to database management, we help you design the right architecture for your business."
        cards={[
          {
            imageSrc: NodejsIcon,
            title: "Node.js",
            description: "We use Node.js to build fast, scalable, and real-time applications. Its non-blocking, event-driven architecture makes it ideal for high-concurrency applications."
          },
          {
            imageSrc: PythonIcon,
            title: "Python",
            description: "Python is perfect for building data-driven backend applications, APIs, and automation scripts. We leverage Python's frameworks like Django and Flask for robust, secure web services."
          },
          {
            imageSrc: JavaIcon,
            title: "Java",
            description: "With its stability and scalability, Java is our go-to language for building enterprise-grade applications, backend systems, and APIs that can handle large-scale data."
          },
          {
            imageSrc: RubyIcon,
            title: "Ruby on Rails",
            description: "Ruby on Rails is a powerful web application framework for building feature-rich and highly functional applications rapidly. We use it to build backend systems with clean code and fast delivery."
          },
          {
            imageSrc: PhpIcon,
            title: "PHP",
            description: "PHP is a highly flexible and widely-used server-side language that excels at building dynamic websites and APIs. Our team uses PHP to create powerful content management systems, eCommerce platforms, and backend systems."
          }
        ]}
      />

      {/* Get Started Section */}
      <GetStarted />

      {/* Contact Us Section */}
      <TwoColContactUsWithIllustrationFullForm />
      
      {/* Footer */}
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import ITConsultingImage from "../../images/itservices.png";
import ITSupportImage from "../../images/Sales consulting-amico.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import ConsultingIcon from "../../images/icons8-consulting-50.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      {/* Hero Section */}
      <TwoColumnWithVideo
        heading="Transform Your Business with Expert IT Services"
        buttonRounded={false}
        primaryButtonText="Request Free Consultation"
        primaryButtonUrl="https://calendly.com/solutions-tbnw/30min?month=2024-11"
        description="We specialize in IT Consulting, IT Staff Augmentation, IT Support, and IT Outsourcing to help businesses scale efficiently. Our team of experts can help you solve complex technology challenges and drive business growth."
        imageSrc={ITConsultingImage} // Adjust based on your preferred hero image
      />

      {/* Main Feature Section */}
      <MainFeature1
        subheading={<Subheading>Your IT Transformation Starts Here</Subheading>}
        heading="Leverage Our IT Expertise for Growth and Efficiency"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contactus"
        description="Our IT services are designed to meet the unique needs of your business, providing customized solutions in consulting, staff augmentation, outsourcing, and support. Let us help you navigate technology challenges with expert guidance."
        imageSrc={ITSupportImage} // Adjust based on your preferred image
        textOnLeft={false}
      />

      {/* IT Services Feature Section */}
      <Features
        heading="Our IT Services"
        description="We offer comprehensive IT services that enable businesses to innovate, scale, and maintain their operations efficiently. Whether you need strategic consulting, additional staff, or fully outsourced IT management, we’ve got the right solution for you."
        cards={[
          {
            imageSrc: ConsultingIcon,
            title: "IT Consulting",
            description: "Our IT consulting services provide expert guidance to help you optimize your technology infrastructure. We advise on digital transformation, IT strategy, system integration, and project management to ensure your business is future-ready."
          },
          {
            imageSrc: ShieldIconImage, 
            title: "IT Staff Augmentation",
            description: "With IT staff augmentation, we provide highly skilled tech talent on demand. Whether you need additional developers, engineers, or IT specialists, we help you scale your team quickly without the overhead of recruitment."
          },
          {
            imageSrc : SupportIconImage,
            title: "IT Support",
            description: "We offer 24/7 IT support to ensure that your technology infrastructure remains operational and secure. Our team handles troubleshooting, system updates, maintenance, and monitoring, so you can focus on your business."
          },
          {
            imageSrc: CustomizeIconImage,
            title: "IT Outsourcing",
            description: "Outsource your IT needs to our team of experts. From network management to software development, we provide cost-effective and scalable IT solutions to help you streamline operations and reduce costs."
          }
        ]}
      />

      {/* Get Started Section */}
      <GetStarted />

      {/* Contact Us Section */}
      <TwoColContactUsWithIllustrationFullForm />
      
      {/* Footer */}
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import TwoColSingleFeatureWithStats from "components/features/TwoColSingleFeatureWithStats";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
 
import de1 from "../../images/de1.png"
import de2 from "../../images/de2.png"

import ContactUs from "pages/ContactUs";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import GetStarted from "components/cta/GetStarted";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default ({ roundedHeaderButton }) => {
  return (
    <AnimationRevealPage>
      <TwoColumnWithVideo
       heading="Data Engineering Services"
       buttonRounded={false}
       primaryButtonText="Request free consultation"
       primaryButtonUrl = "https://calendly.com/solutions-tbnw/30min?month=2024-11"
       description="At Metromidas, we specialize in providing Data Engineering services that help businesses harness the full potential of their data. From designing scalable data architectures to processing and optimizing large datasets, our team of experts enables you to turn complex data into valuable insights that drive smarter decision-making and business growth."
       imageSrc={de1}
       />
        <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Transform Your Data into Business Value"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl = "/contactus"
        description="At Metromidas, our Data Engineering services empower businesses to maximize the value of their data. Whether you're looking to design a scalable data architecture, integrate multiple data sources, or implement advanced data processing pipelines, we have the expertise to deliver high-performance solutions that align with your goals."
        
        imageSrc={de2}
        textOnLeft={false}
      />
       <GetStarted />

<TwoColContactUsWithIllustrationFullForm />
      <Footer />
    </AnimationRevealPage>
    
  );
};
